// Variable overrides
.logo-texto {
    font-size: 1em !important;
    color: white;
    text-align: right;
    margin-top: 0px !important;
    padding: 5px;
}
.logoStudio {
    width: auto;
    height: 14px;
    margin-top: 5px;
}

.national-landing {
    background-color: #0088ce !important;
    
    .btn-primary {
        background-color: #0088ce !important;
        border: 0px;
        font-family: Grotesk;
        //margin-top: 1.5rem;
    }
    
    .login-text1 {
        text-align: center;
        font-size: 37px;
        font-family: Grotesk;
        color: white;
    }
    .login-text2 {
        text-align: center;
        font-size: 37px;
        font-family: Grotesk;
//        text-indent: 5em;
        
    }

    .header{
    
        font-size: 25px ;
        font-family: Grotesk ;
        padding-bottom: 30px ;
        color: #0088ce ;
    }
    
    label{
        font-size: 15px !important;
        font-family: Grotesk !important;
        color: gray !important;
    }
    
    .second{
        font-size: 15px !important;
        font-family: Grotesk !important;
        color: gray ;
    }
    
}

.the-cortland {
    background-color: #3c2128 !important;

    .btn-primary {
        background-color: #3c2128 !important;
        border: 0px;
    }
    .nav-link {
        color: white !important;
    }

    .brand-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: auto;
        padding-bottom: 15px;
    }
}

.city-ridge {
    background-color: #424F60 !important;

    .btn-primary {
        background-color: #424F60 !important;
        border: 0px;
    }
    .nav-link {
        color: white !important;
    }

    .brand-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 360px;
        height: auto;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }
}